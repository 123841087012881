import { createWebHashHistory, createRouter } from "vue-router";
import pathLoader from "../plugins/loader";
import store from "../store/store";
import http from "@/plugins/http";

const routes = [
  {
    path: "/login",
    name: "login",
    component: pathLoader("login"),
  },
  {
    path: "/",
    name: "signin",
    component:() => import('@/views/login/client-signin'),
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: pathLoader("forgetPassword"),
  },
  {
    path: "/set-password",
    name: "newPassword",
    component: pathLoader("newPassword"),
    meta: {
      token: false,
    },
  },
  {
    path: "/rapport",
    name: "rapport",
    component: pathLoader("rapport"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/statistiques",
    name: "statistiques",
    component: pathLoader("statistiques"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/campagnes",
    name: "campagnes",
    component: pathLoader("campagnes"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/admin",
    name: "homePrivate",
    component: pathLoader(null, "homePrivate"),
    redirect: "dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: pathLoader("dashboard"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/client",
        name: "prospect",
        component: pathLoader("prospect"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/campagne",
        name: "campagne",
        component: pathLoader("campagne"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/calendrier",
        name: "calendrier",
        component: ()=> import ('@/views/calendrier/CalendarVue.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/campagne/details",
        name: "campagneDetail",
        component: () => import('@/views/campagne/details'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/paiement/details",
        name: "paiementDetails",
        component: () => import('@/views/paiements/paiementDetails'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/prospect/details",
        name: "prospectDetail",
        component: () => import('@/views/prospect/details'),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/agents",
        name: "agents",
        component: pathLoader("agents"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/prestataires",
        name: "prestataires",
        component: pathLoader("prestataires"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/prestataire/details",
        name: "prestataireDetail",
        component: () => import('@/views/prestataires/details'),
        meta: {
          requiresAuth: true,
        },
      },


      {
        path: "/parametres",
        name: "parametres",
        component: pathLoader("parametres"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/administrateurs",
        name: "administrateurs",
        component: pathLoader("administrateurs"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let code = window.location.search

    if (store.getters.token) {
      http.defaults.headers.common.Authorization = 'Bearer ' + store.getters.token
      next();
        return;
    } else  {
      if (code) {
        store.dispatch('saveRedirect', window.location.search)
      }

      next("/login");
    }

  } else {
    if (to.matched.some((record) => record.meta.token)) {
      const query = window.location.search
      if (query) {
        if (query.indexOf('id') > 0 && query.indexOf('token') > 0) {
          next();
        }
      }

    } else {
      next();
    }

  }
});

export default router;
