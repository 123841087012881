import axios from "axios";
import apiRouter from "../router/api-routes";
import store from "@/store/store";
import * as https from "https";

const http = axios.create({
  baseURL: apiRouter.baseURL,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
});

const agent = new https.Agent({
  rejectUnauthorized: false,
});
// Add agent to http defaults
http.defaults.httpsAgent = agent;
http.defaults.headers.common['x-api-key'] = '78fb6691-2e77-480a-8001-09d3585cceee';
http.defaults.headers.common.Authorization = 'Bearer ' + store.getters.token

http.interceptors.request.use(
  function (request) {
    if (!request.baseURL.indexOf(apiRouter.baseURL)) {
      console.log("owner site");
    } else {
      console.log("other site");
    }
    return request;
  },
  function (error) {
    return error;
  }
);

http.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(
      error.response || {
        response: { status: error.statusCode, message: error.message },
      }
    );
  }
);


export default http;
