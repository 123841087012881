import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store/store";
import router from "./router";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import install from "./install";
import i18n from "./i18n";
import easySpinner from "vue-easy-spinner";
import Switches from "vue-switches";
import vClickOutside from "click-outside-vue3";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import Toaster from "@incuca/vue3-toaster";
import VueClipboard from 'vue3-clipboard'
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { VueFire, VueFireAuth } from 'vuefire'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import VueGoogleMaps from '@fawmi/vue-google-maps';

export const firebaseApp = initializeApp({
    // apiKey: "AIzaSyCNzRhN6OtEg8pT43dyWhs0r1Bsvi15zXQ",
    // authDomain: "panneaumobile.firebaseapp.com",
    // databaseURL: "https://panneaumobile-default-rtdb.firebaseio.com",
    // projectId: "panneaumobile",
    // storageBucket: "panneaumobile.appspot.com",
    // messagingSenderId: "1044643288808",
    // appId: "1:1044643288808:web:ee0225f9a7a5fd8df19b5d",
    // measurementId: "G-VPJJXLLPNT"

    apiKey: "AIzaSyDB2QmOlgMFibj2gOaSrXok2T8STCFAlxM",
    authDomain: "pige-panneau-mobile.firebaseapp.com",
    projectId: "pige-panneau-mobile",
    storageBucket: "pige-panneau-mobile.appspot.com",
    messagingSenderId: "971066196811",
    appId: "1:971066196811:web:74e882f74888c15ffe5ece",
    measurementId: "G-TRPH3V7JGS"
})

// used for the firestore refs
export const db = getFirestore(firebaseApp)

createApp(App)
  .use(i18n)
  .use(install)
    .use(Toaster)
  .use(easySpinner, {
    /*options*/
    prefix: "easy",
  })
    .use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
        // we will see other modules later on
        VueFireAuth(),
    ],
})
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDmxPl6DxUm5zw4GGROHZY1uYAI-_IkhBk',
        },
    })
    .use( CKEditor )
    .use(VueApexCharts)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
  .use(VCalendar, {})
  .use(vClickOutside)
    .use(VueApexCharts)
  .use(Switches)
    .component('VueDatePicker', VueDatePicker)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(store)
  .use(router)

  .mount("#app");
