import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import navbar_shower from "./navbar_shower";

const vuex = createStore({
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    infoTampon: null,
    selectedYear: null,
  },

  mutations: {
    save_token(state, position) {
      state.token = position;
    },

    save_tampon(state, position) {
      state.infoTampon = position;
    },

    save_user(state, position) {
      state.user = position;
    },

    logout(state) {
      state.token = null;
      state.user = null;
      state.infoTampon = null;
    },
  },

  actions: {

    saveToken({ commit }, c) {
      commit("save_token", c);
    },
    saveTampon({ commit }, c) {
      commit("save_tampon", c);
    },

    saveUser({ commit }, c) {
      commit("save_user", c);
    },

    logout({ commit }, e) {
      commit("logout", e);
    },
  },

  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    infoTampon: (state) => state.infoTampon,
  },

  modules: {
    navbar_shower,
  },
});

export default vuex;
