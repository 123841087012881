import mutations from "./mutations";
export default {
  namespaced: true,

  state() {
    return {
      resp_nav_visivible: false,
    };
  },

  mutations,
};
